import * as React from 'react'
import { Card, Carousel } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './ServicesActivités.page.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, A11y } from 'swiper/modules'
import {
  faUtensils,
  faBicycle,
  faPlug,
  faBus,
  faUsers,
  faTree,
  faMotorcycle,
  faCompass,
  faCloud,
  faHorse,
  faWifi,
  faPersonHiking,
  faChampagneGlasses,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

function ServicesActivitesContent() {
  const { t } = useTranslation()

  return (
    <div>
      <div className="container mx-auto p-5">
        <section className="mb-10">
          <h2 className="text-xl font-bold text-stone-600 mb-5 mt-10">{t('services_title')}</h2>
          <div className="m-4">{t('services_activitates_intro')}</div>

          <div className="text-center">
            {/* Cartes avec des icônes FontAwesome */}
            <Swiper
              modules={[Navigation, A11y]}
              spaceBetween={50}
              slidesPerView={3}
              navigation
              pagination={{ clickable: true }}
              breakpoints={{
                // Lorsque la largeur de la fenêtre est >= 640px
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // Lorsque la largeur de la fenêtre est >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                // Lorsque la largeur de la fenêtre est >= 1024px
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
              }}
            >
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faUtensils} size="2x" className="mb-2" />
                  <p className="font-bold">{t('service_food')}</p>
                  <p>{t('service_food_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faBicycle} size="2x" className="mb-2" />
                  <p className="font-bold">{t('service_bike')}</p>
                  <p>{t('service_bike_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faWifi} size="2x" className="mb-2" />
                  <p className="font-bold">{t('service_parking')}</p>
                  <p>{t('service_parking_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faPlug} size="2x" className="mb-2" />
                  <p className="font-bold">{t('service_ev_charging')}</p>
                  <p>{t('service_ev_charging_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faBus} size="2x" className="mb-2" />
                  <p className="font-bold">{t('service_bus_stop')}</p>
                  <p>{t('service_bus_stop_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faUsers} size="2x" className="mb-2" />
                  <p className="font-bold">{t('service_meeting_rooms')}</p>
                  <p>{t('service_meeting_rooms_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faTree} size="2x" className="mb-2" />
                  <p className="font-bold">{t('service_leisure_areas')}</p>
                  <p>{t('service_leisure_areas_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faUtensils} size="2x" className="mb-2" />
                  <p className="font-bold">{t('service_tourist_office')}</p>
                  <p>{t('service_tourist_office_desc')}</p>
                </Card>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        {/* Vous pouvez ajouter une section similaire pour les activités ici */}
      </div>
      <div className="border-t-2" />

      <div className="container mx-auto p-5">
        <h2 className="text-xl font-bold text-stone-600 mb-10 px-5">{t('activite_title')}</h2>

        <section className="mb-10">
          <div className="m-4">{t('activite_activitates_intro')}</div>

          <div className="text-center">
            {/* Cartes avec des icônes FontAwesome */}
            <Swiper
              modules={[Navigation, A11y]}
              spaceBetween={50}
              slidesPerView={3}
              navigation
              pagination={{ clickable: true }}
              breakpoints={{
                // Lorsque la largeur de la fenêtre est >= 640px
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // Lorsque la largeur de la fenêtre est >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                // Lorsque la largeur de la fenêtre est >= 1024px
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
              }}
            >
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faMotorcycle} size="2x" className="mb-2" />
                  <p className="font-bold">{t('activite_cyclotourisme')}</p>
                  <p>{t('activite_cyclotourisme_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faCompass} size="2x" className="mb-2" />
                  <p className="font-bold">{t('activite_tourisme')}</p>
                  <p>{t('activite_tourisme_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faChampagneGlasses} size="2x" className="mb-2" />
                  <p className="font-bold">{t('activite_oenotourisme')}</p>
                  <p>{t('activite_oenotourisme_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <div>
                    <FontAwesomeIcon icon={faPersonHiking} size="2x" className="mb-2 mr-5" />
                    <FontAwesomeIcon icon={faBicycle} size="2x" className="mb-2" />
                  </div>
                  <p className="font-bold">{t('activite_sport')}</p>
                  <p>{t('activite_sport_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faHorse} size="2x" className="mb-2" />
                  <p className="font-bold">{t('activite_equitation')}</p>
                  <p>{t('activite_equitation_desc')}</p>
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card>
                  <FontAwesomeIcon icon={faCloud} size="2x" className="mb-2" />
                  <p className="font-bold">{t('activite_montgolfiere')}</p>
                  <p>{t('activite_montgolfiere_desc')}</p>
                </Card>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        {/* Vous pouvez ajouter une section similaire pour les activités ici */}
      </div>
      <Carousel className="md:w-3/5 mx-auto" style={{ height: '450px' }}>
        <img src="./images/activite/activite_1.png" alt="Images description des chambres" />
        <img src="./images/activite/activite_2.png" alt="Images description" />
        <img src="./images/activite/activite_3.png" alt="Images description" />
        <img src="./images/activite/activite_4.png" alt="Images description" />
        <img src="./images/activite/activite_5.png" alt="Images description" />
        <img src="./images/activite/activite_6.png" alt="Images description" />
        <img src="./images/activite/activite_7.png" alt="Images description" />
        <img src="./images/activite/activite_8.png" alt="Images description" />
        <img src="./images/activite/activite_9.jpg" alt="Images description" />
      </Carousel>
    </div>
  )
}

export default ServicesActivitesContent
