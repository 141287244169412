import * as React from 'react'

import { Dropdown, Navbar } from 'flowbite-react'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../LanguageSelector/LanguageSelector.component'
import './Navbar.style.css'

function NavbarComponent() {
  const { t } = useTranslation()
  const url = window.location.pathname
  return (
    <Navbar fluid rounded className="bg-stone-800/70 rounded-none fixed top-0 w-screen z-50">
      <Navbar.Brand href="/" className="pl-10">
        <img src="/images/Binson-logo-white.png" className="mr-6 h-6 sm:h-12 p-0" alt="Binson logo" />
        <div className="flex flex-col md:flex-row">
          <span className="self-center whitespace-nowrap text-xl font-medium text-white logo-title">{t('navbar_brand')}</span>
          <span className="self-center whitespace-nowrap text-lg font-medium ml-1 text-amber-600 logo-title"> {t('navbar_brand_subtitle')}</span>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="pr-10">
        <Navbar.Link href="/" active={url === '/'} className="mt-2">
          {t('navbar_home')}
        </Navbar.Link>
        <Navbar.Link href="/hebergements" active={url === '/hebergements'} className="mt-2">
          {t('navbar_hebergements')}
        </Navbar.Link>
        <Navbar.Link href="/salles" active={url === '/salles'} className="mt-2">
          {t('navbar_salles')}
        </Navbar.Link>
        <Navbar.Link href="/services-activities" active={url === '/services-activities'} className="mt-2">
          {t('navbar_services_activities')}
        </Navbar.Link>
        <li className="pl-3 py-2">
          <Dropdown arrowIcon={false} inline label={t('navbar_evenements')}>
            <Navbar.Link href="/evenements-pro" active={url === '/evenements-pro'}>
              <Dropdown.Item>{t('dropdown_pro')}</Dropdown.Item>{' '}
            </Navbar.Link>
            <Navbar.Link href="/evenements-pv" active={url === '/evenements-pv'}>
              <Dropdown.Item>{t('dropdown_private')}</Dropdown.Item>{' '}
            </Navbar.Link>
          </Dropdown>
        </li>
        <Navbar.Link href="/contact" active={url === '/contact'} className="mt-2">
          {t('navbar_contact')}
        </Navbar.Link>
        <Navbar.Link className="mt-2">
          <LanguageSelector />
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavbarComponent
