import * as React from 'react'

import { Button, Label, TextInput, Select, Alert } from 'flowbite-react'

import { useTranslation } from 'react-i18next'
import useFormulaire from '../../hooks/useFormulaire'

function FormulaireComponent() {
  const { t } = useTranslation()
  const { name, setName, mail, setMail, objet, setObject, about, setAbout, notification, handleSubmit } = useFormulaire()

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <h1 className="text-center text-xl">{t('contact_form_title')}</h1>
      <div className="w-full flex max-w-md flex-col">
        {notification && (
          <Alert color={notification.type === 'success' ? 'success' : 'failure'} className="mb-4">
            {notification.message}
          </Alert>
        )}
        <form className="w-full flex max-w-md flex-col gap-4 " method="post" action="" onSubmit={handleSubmit}>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="name" value={t('contact_form_lastname')} />
            </div>
            <TextInput id="text" type="text" name="name" placeholder={t('contact_form_lastname')} value={name} required onChange={event => setName(event.target.value)} />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="mail" value={t('contact_form_email')} />
            </div>
            <TextInput id="email1" name="mail" type="email" placeholder="email@adresse.com" value={mail} required onChange={event => setMail(event.target.value)} />
          </div>
          <div className="max-w-md">
            <div className="mb-2 block">
              <Label htmlFor="objet" value={t('contact_form_object')} />
            </div>
            <Select id="objet" name="objet" required value={objet} onChange={event => setObject(event.target.value)}>
              <option>{t('contact_form_objet_event')}</option>
              <option>{t('contact_form_objet_marriage')}</option>
              <option>{t('contact_form_objet_accommodation')}</option>
              <option>{t('contact_form_objet_others')}</option>
            </Select>
          </div>
          <div className="col-span-full">
            <Label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
              {t('contact_form_message')}
            </Label>
            <div className="mt-2">
              <textarea
                id="about"
                name="about"
                rows={3}
                required
                value={about}
                onChange={event => setAbout(event.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <Button type="submit" color="primary" disabled={name === '' && mail === '' && objet === '' && about === ''}>
            {t('contact_form_send')}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default FormulaireComponent
