import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Carousel } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faTree, faBuilding, faLeaf } from '@fortawesome/free-solid-svg-icons'
import BackgroundedSection from '../BackgroundedSection/BackgroundedSection.component'

function EvenementProComponent() {
  const { t } = useTranslation()

  return (
    <div>
      <div className="px-5">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-10 text-center gap-10 mx-auto container">
          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faBook} size="2x" className="mb-2" />
            <div>{t('event_pro_formations')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faTree} size="2x" className="mb-2" />
            <div>{t('event_pro_retraite_equipe')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faLeaf} size="2x" className="mb-2" />
            <div>{t('event_pro_mise_vert')}</div>
          </Card>

          <Card className="flex flex-col items-center justify-center text-center p-4">
            <FontAwesomeIcon icon={faBuilding} size="2x" className="mb-2 mr-5" />
            <div>{t('event_pro_hard_working')}</div>
          </Card>
        </div>
      </div>
      <BackgroundedSection>
        <div className="mb-5 font-bold mx-auto container text-center">{t('event_pro_highlighted_title')}</div>
        <div className="mb-5 mx-auto container">{t('event_pro_highlighted_text')}</div>
        <ul className="list-disc mx-auto container">
          <li>{t('event_pro_list_item1')}</li>
          <li>{t('event_pro_list_item2')}</li>
          <li>{t('event_pro_list_item3')}</li>
          <li>{t('event_pro_list_item4')}</li>
          <li>{t('event_pro_list_item5')}</li>
          <li>{t('event_pro_list_item6')}</li>
          <li>{t('event_pro_list_item7')}</li>
        </ul>
      </BackgroundedSection>
      <div className="grid gric-cols-1 md:grid-cols-2  gap-10 container mx-auto">
        <img alt="temp imgg3" src="./images/event_pro/event_pro_6.jpg" />
        <div>
          <h1 className="text-xl text-center font-bold text-stone-600 mb-5 mx-auto container mt-10">{t('event_pro_subtitle')}</h1>
          <p className="max-w-3xl text-centermb-10 mx-5 text-center">{t('event_pro_desc')}</p>
          <Button name="contact-button" href="/contact" color="primary" className="mt-5">
            {t('home_contact_button')}
          </Button>
        </div>
      </div>
      <Carousel className="md:w-3/5 mx-auto mt-20" style={{ height: '450px' }}>
        <img src="./images/event_pro/event_pro_1.png" alt="#1" />
        <img src="./images/event_pro/event_pro_2.jpg" alt="#2" />
        <img src="./images/event_pro/event_pro_3.png" alt="#3" />
        <img src="./images/event_pro/event_pro_4.png" alt="#4" />
        <img src="./images/event_pro/event_pro_5.jpg" alt="#5" />
        <img src="./images/event_pro/event_pro_6.jpg" alt="#6" />
        <img src="./images/event_pro/event_pro_7.jpg" alt="#7" />
        <img src="./images/event_pro/event_pro_8.png" alt="#8" />
        <img src="./images/event_pro/event_pro_9.jpg" alt="#9" />
      </Carousel>
    </div>
  )
}
export default EvenementProComponent
