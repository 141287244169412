import { useState } from 'react'
import axios from 'axios'

export default function useFormulaire() {
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [objet, setObject] = useState('')
  const [about, setAbout] = useState('')

  const [notification, setNotification] = useState<{ type: string; message: string }>() // État pour les notifications

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    try {
      await axios
        .get('/sendMail.php', {
          params: {
            lastname: name,
            email: mail,
            object: objet,
            message: about,
          },
        })
        .then(response => {
          if (response.data === 'error') {
            setNotification({
              type: 'error',
              message: `Erreur lors de l'envoi du message : si vous avez envoyé plusieurs messages rapidement, veuillez patienter quelques minutes.`,
            })
          } else {
            setNotification({ type: 'success', message: 'Message envoyé avec succès !' })
            // Réinitialiser les champs du formulaire
            setName('')
            setMail('')
            setObject('')
            setAbout('')
          }
        })
    } catch (error) {
      setNotification({ type: 'error', message: `Erreur lors de l'envoi du message : si vous avez envoyé plusieurs messages rapidement, veuillez patienter quelques minutes.` })
      console.error("Erreur lors de l'envoi du formulaire:", error)
    }
  }

  return { name, setName, mail, setMail, objet, setObject, about, setAbout, notification, handleSubmit }
}
